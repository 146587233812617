import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BGImg from 'gatsby-background-image'

import './index.css'

import Layout from '../components/layout'
import SEO from '../components/seo'

const KontaktPage = () => {
  const data = useStaticQuery(graphql`
    query {
      brief: file(relativePath: { eq: "kontakt/brief.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='CSU Neutraubling' />
      <BGImg
        className='jumbotron jumbotron-fluid'
        fluid={data.brief.childImageSharp.fluid}>
        <div className='container'>
          <h1
            className='display-4 text-light mobile-header'
            style={{ textShadow: '0 0 10px black', fontWeight: 'normal' }}>
            Kontakt
          </h1>
        </div>
      </BGImg>
      <div className='container my-5'>
        <p>
          Über Anfragen, Hinweise oder auch alle anderen Kontakte mit Ihnen
          würden wir uns freuen. Benutzen Sie hierfür einfach das
          Kontaktformular, schreiben uns über{' '}
          <a href='https://www.facebook.com/CSUNeutraubling/' target='_blank'>
            Facebook
          </a>{' '}
          oder auf{' '}
          <a href='mailto:kontakt@csu-neutraubling.de'>
            kontakt@csu-neutraubling.de
          </a>
        </p>

        <form data-netlify='true' name='kontakt' method='POST' action='/danke'>
          <input type='hidden' name='form-name' value='kontakt' />
          <div className='form-group'>
            <label className='form-label' htmlFor='formBasicEmail'>
              Ihre E-Mail-Adresse
            </label>
            <input
              placeholder='Geben Sie Ihre E-Mail-Adresse ein'
              name='E-Mail-Adresse'
              type='email'
              id='formBasicEmail'
              className='form-control'
            />
            <small className='text-muted form-text'>
              Wir teilen Ihre E-Mail-Adresse nicht mit Dritten und verwenden Sie
              ausschließlich, um Ihnen zu antworten.
            </small>
          </div>
          <div className='form-group'>
            <label
              className='form-label'
              htmlFor='exampleForm.ControlTextarea1'>
              Ihre Nachricht
            </label>
            <textarea
              rows={3}
              name='Nachricht'
              id='exampleForm.ControlTextarea1'
              className='form-control'></textarea>
          </div>
          <button type='submit' className='btn btn-primary'>
            Absenden
          </button>
        </form>
      </div>
    </Layout>
  )
}

export default KontaktPage
